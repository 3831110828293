const apiContainer = {
  department: "/department",
  designation: "/designation",
  state: "/state",
  district: "/district",
  country: "/country",
  category: "/category",
  subCategory: "/sub-category",
  applicantType: "/applicant-type",
  disbursedMethod: "/disbursed-method",
  expensiveType: "/expensive-type",
  incomeType: "/income-type",
  addressType: "/address-type",
  proofType: "/proof-type",
  bankAccount: "/bank-account",
  loanChargesDetails: "/loan-charges-details",
  loanChargesType: "/loan-charges-type",
  expenseEntry: "/expense-entry",
  incomeEntry: "/income-entry",
  applicant: "/applicant",
  role: "/role",
  // addLoan: "/loan-details",
  addLoan: "/loan",
  employee: "/employee",
  applicantInfo: "/applicant-info",
  duePayment: "/due-payment",
  applicantAddress: "/applicant-address",
  applicantProof: "/applicant-proof",
  investment: "/investment",
  investmentDetails: "/investment-details",
  duePaymentDetails: "/due-payment-details",
  duePaymentHistory: "/due-payment-history",
  dayBook: "/day-book",
  dayBookHistory: "/day-book-history",
  employeeLedger: "/employee-ledger",
  customerLedger: "/customer-ledger",
  ledgerDetails: "/ledger-details",
  investorDuePaymentHistory: "/investment-receipt",
  investorDuePaymentDetails: "/investment-receipt-details",
  searchApplicant: "/search-applicant",
  searchApplicantDetails: "/search-applicant-details",
  employeeAttendance: "/employee-attendance",
  employeeAttendanceReport: "/employee-attendance-report",
  enquiry: "/enquiry",
  employeeLogin: "/organization-login",
  faq: "/faq",
  uploadImages: "/upload-proof",
  contra: "/contra",
  contraDetails: "/contra-history-details",
};

export default apiContainer;
